import dayjs from 'dayjs';
import { PageProps } from 'gatsby';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonGroup from '~/components/Button/Group';
import Header from '~/components/Header';
import Notebook from '~/components/Notebook';
import NotebookForm from '~/components/Notebook/Form';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useNotes from '~/utils/useNotes';

const NotebookPage: FC<EspaceProps & PageProps & UserProps> = ({
  espace,
  location,
  user,
}) => {
  const items = useNotes({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  const today = dayjs().format('YYYY-MM-DD');
  const [range, setRange] = useState<string[]>();
  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);
  const { t } = useTranslation();

  useEffect(() => {
    const date = [startDate ?? dayjs().format('YYYY-MM-DD')];
    if (endDate && startDate && startDate.localeCompare(endDate) !== 0) {
      let tmp = dayjs(startDate, 'YYYY-MM-DD').add(1, 'day');
      const end = dayjs(endDate, 'YYYY-MM-DD');
      while (tmp.diff(end, 'day') <= 0) {
        date.push(tmp.format('YYYY-MM-DD'));
        tmp = tmp.add(1, 'day');
      }
    }
    setRange(date);
  }, [endDate, startDate]);

  // const handleStartVideoRecording = () => {
  //   console.info(
  //     "navigator.userAgent.includes('iPhone'",
  //     navigator.userAgent.includes('iPhone'),
  //   );
  //   if (!navigator.userAgent.includes('iPhone')) {
  //     setHasVideo(true);
  //     setIsRecording(true);
  //     start({ audio: true, video: true });
  //   } else {
  //     const file = document.querySelector(
  //       '#input-file__image',
  //     ) as HTMLInputElement;
  //     if (file) {
  //       file.click();
  //     }
  //   }
  // };

  const handleStartDateOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setStartDate(event.currentTarget.value);
  };

  const handleEndDateOnChange = (event: SyntheticEvent<HTMLInputElement>) => {
    setEndDate(event.currentTarget.value);
  };

  return (
    <TemplateEspace espace={espace} pathname={location.pathname} user={user}>
      <div className="flex flex-col overflow-auto bg-background md:mx-6">
        <Header subMenu={items} title={t('notes.title')} />
        <div className="flex justify-center md:mx-0 md:justify-start">
          <ButtonGroup>
            <input
              className="whitespace-nowrap rounded-[15px] border bg-light-400 py-0.5 pl-3 pr-1.5 outline-none"
              max={endDate}
              onChange={handleStartDateOnChange}
              type="date"
              value={startDate}
            />
            <input
              className="whitespace-nowrap rounded-[15px] border bg-light-400 py-0.5 pl-1.5 pr-3 outline-none"
              max={today}
              min={startDate}
              onChange={handleEndDateOnChange}
              type="date"
              value={endDate}
            />
          </ButtonGroup>
        </div>
        <div className="mx-6 mt-6 md:mx-0">
          {range &&
            range.length > 0 &&
            range.map(date => (
              <Notebook
                key={date}
                date={date}
                espaceId={espace.id}
                showInput={false}
              />
            ))}
          <NotebookForm espaceId={espace.id} />
        </div>
      </div>
    </TemplateEspace>
  );
};

export default requireEspace(NotebookPage);
